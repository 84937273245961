<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/schedule-top-img.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="4"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="4"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- 内容开始 -->
    <div class="middle">
      <div class="container">
        <!-- 标题部分开始 -->
        <div class="title">
          {{ $t("message.topNav5") }}
          <span class="small"></span>
          <span class="big"></span>
        </div>
        <!-- 标题部分结束 -->
        <!-- 内容开始 -->
        <div class="middle-content hidden-xs-only">
          <div class="middle-item" v-for="item in lists" :key="item.id">
            <!-- 日期开始 -->
            <div class="middle-item-date">
              <div class="middle-item-date-year">{{ item.dayStr }}</div>
              <div class="middle-item-date-day">{{ item.yearMouthStr }}</div>
            </div>
            <!-- 日期结束 -->
            <!-- 右侧内容开始 -->
            <div class="middle-item-box" @click="handleDetail(item.id)">
              <!-- 标题开始 -->

              <div class="middle-item-title">
                <div v-show="language == 'cn'">{{ item.title }}</div>
                <div v-show="language == 'en'">{{ item.enTitle }}</div>
              </div>
              <!-- 标题结束 -->
              <!-- 内容开始 -->

              <div class="middle-item-text">
                <div v-show="language == 'cn'" v-html="item.content"></div>
                <div v-show="language == 'en'" v-html="item.content"></div>
              </div>
              <!-- 内容结束 -->
              <!-- 标签开始 -->
              <div class="middle-item-tag">
                <span v-show="language == 'cn'">{{ item.typeName }}</span>
                <span v-show="language == 'en'">{{ item.enType }}</span>
              </div>
              <!-- 标签结束 -->
            </div>
            <!-- 右侧内容结束 -->
          </div>
        </div>
        <div class="middle-content hidden-sm-and-up">
          <div class="middle-item-sm" v-for="item in lists" :key="item.id">
            <!-- 日期开始 -->
            <div class="middle-item-date-sm">
              <div class="middle-item-date-year-sm">{{ item.dayStr }}</div>
              <div class="middle-item-date-day-sm">{{ item.yearMouthStr }}</div>
            </div>
            <!-- 日期结束 -->
            <!-- 右侧内容开始 -->
            <div class="middle-item-box-sm" @click="handleDetail(item.id)">
              <!-- 标题开始 -->
              <div class="middle-item-title-sm">
                <div v-show="language == 'cn'">{{ item.title }}</div>
                <div v-show="language == 'en'">{{ item.enTitle }}</div>
              </div>
              <!-- 标题结束 -->
              <!-- 标签开始 -->
              <div class="middle-item-tag-sm">
                <span v-show="language == 'cn'">{{ item.typeName }}</span>
                <span v-show="language == 'en'">{{ item.enType }}</span>
              </div>
              <!-- 标签结束 -->
            </div>
            <!-- 右侧内容结束 -->
          </div>
        </div>
        <!-- 内容结束 -->
        <!-- 分页开始 -->
        <div class="local-pagination">
          <div v-show="language == 'cn'">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :hide-on-single-page="true"
              :page-size="10"
              layout="prev, pager, next"
              prev-text=" 上一页 "
              next-text="下一页"
              :total="pageTotal"
              :current-page="pageIndex"
            >
            </el-pagination>
          </div>
          <div v-show="language == 'en'">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :hide-on-single-page="true"
              :page-size="10"
              layout="prev, pager, next"
              prev-text=" prev "
              next-text="next"
              :total="pageTotal"
              :current-page="pageIndex"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 分页结束 -->
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      pageTotal: 0, //分页总数
      pageIndex: 1,
      lists: [], //数据
    };
  },
  mounted() {
    this.getLists();
  },
  methods: {
    // 获取数据
    getLists() {
      this.$axios
        .get(`api/infomationList?state=1&limit=10&page=${this.pageIndex}`, {})
        .then((res) => {
          if (res.data.code == 0) {
            this.pageTotal = res.data.count;
            this.lists = res.data.data;
          }
        });
    },
    // 翻页方法
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getLists();
    },
    // 查看详情
    handleDetail(value) {
      this.$router.push({ path: "/newsDetail", query: { id: value } });
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.middle-content {
  min-height: 600px;
}

.middle-item {
  height: 130px;
  padding: 30px 0;
  display: flex;
}

.middle-item + .middle-item {
  border-top: 1px dashed #c0c0c0;
}

.middle-item-date {
  width: 130px;
  height: 130px;
  background: #004bc7;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.middle-item-date-year {
  color: #ffffff;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

.middle-item-date-day {
  color: #ffffff;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: 12px;
}

.middle-item-box {
  margin-left: 40px;
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.middle-item-title {
  color: #333333;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.middle-item-text {
  color: #666666;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.middle-item-tag span {
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border: 1px solid #004bc7;
  display: inline-block;
  padding: 5px 11px;
}

.local-pagination {
  padding: 40px 0 80px;
  display: flex;
  justify-content: center;
}

.middle-item-sm {
  height: 68px;
  padding: 20px 0;
  display: flex;
}

.middle-item-sm + .middle-item-sm {
  border-top: 1px dashed #c0c0c0;
}

.middle-item-date-sm {
  width: 68px;
  height: 68px;
  background: #004bc7;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.middle-item-date-year-sm {
  color: #ffffff;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}

.middle-item-date-day-sm {
  color: #ffffff;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: 8px;
}

.middle-item-box-sm {
  margin-left: 20px;
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.middle-item-title-sm {
  color: #333333;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.middle-item-tag-sm span {
  color: #004bc7;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.local-pagination {
  padding: 40px 0 80px;
  display: flex;
  justify-content: center;
}

.local-pagination >>> .btn-prev,
.local-pagination >>> .btn-next {
  padding: 0 8px;
}
</style>
